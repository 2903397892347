exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-chain-restaurants-js": () => import("./../../../src/pages/chain-restaurants.js" /* webpackChunkName: "component---src-pages-chain-restaurants-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-custom-ordering-website-js": () => import("./../../../src/pages/custom-ordering-website.js" /* webpackChunkName: "component---src-pages-custom-ordering-website-js" */),
  "component---src-pages-customer-stories-cviche-js": () => import("./../../../src/pages/customer-stories-cviche.js" /* webpackChunkName: "component---src-pages-customer-stories-cviche-js" */),
  "component---src-pages-customer-stories-jonahs-kabob-js": () => import("./../../../src/pages/customer-stories-jonahs-kabob.js" /* webpackChunkName: "component---src-pages-customer-stories-jonahs-kabob-js" */),
  "component---src-pages-customer-stories-js": () => import("./../../../src/pages/customer-stories.js" /* webpackChunkName: "component---src-pages-customer-stories-js" */),
  "component---src-pages-customer-stories-pops-artisanal-creamery-js": () => import("./../../../src/pages/customer-stories-pops-artisanal-creamery.js" /* webpackChunkName: "component---src-pages-customer-stories-pops-artisanal-creamery-js" */),
  "component---src-pages-dine-in-ordering-js": () => import("./../../../src/pages/dine-in-ordering.js" /* webpackChunkName: "component---src-pages-dine-in-ordering-js" */),
  "component---src-pages-features-overview-js": () => import("./../../../src/pages/features-overview.js" /* webpackChunkName: "component---src-pages-features-overview-js" */),
  "component---src-pages-franchise-js": () => import("./../../../src/pages/franchise.js" /* webpackChunkName: "component---src-pages-franchise-js" */),
  "component---src-pages-frequently-asked-questions-js": () => import("./../../../src/pages/frequently-asked-questions.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-js" */),
  "component---src-pages-ghost-kitchen-js": () => import("./../../../src/pages/ghost-kitchen.js" /* webpackChunkName: "component---src-pages-ghost-kitchen-js" */),
  "component---src-pages-guest-feedback-monitoring-js": () => import("./../../../src/pages/guest-feedback-monitoring.js" /* webpackChunkName: "component---src-pages-guest-feedback-monitoring-js" */),
  "component---src-pages-independent-restaurants-js": () => import("./../../../src/pages/independent-restaurants.js" /* webpackChunkName: "component---src-pages-independent-restaurants-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-old-js": () => import("./../../../src/pages/index-old.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-pages-integrations-js": () => import("./../../../src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-local-business-optimization-js": () => import("./../../../src/pages/local-business-optimization.js" /* webpackChunkName: "component---src-pages-local-business-optimization-js" */),
  "component---src-pages-loyalty-reward-program-js": () => import("./../../../src/pages/loyalty-reward-program.js" /* webpackChunkName: "component---src-pages-loyalty-reward-program-js" */),
  "component---src-pages-marketing-solutions-js": () => import("./../../../src/pages/marketing-solutions.js" /* webpackChunkName: "component---src-pages-marketing-solutions-js" */),
  "component---src-pages-menu-management-js": () => import("./../../../src/pages/menu-management.js" /* webpackChunkName: "component---src-pages-menu-management-js" */),
  "component---src-pages-orders-consolidation-js": () => import("./../../../src/pages/orders-consolidation.js" /* webpackChunkName: "component---src-pages-orders-consolidation-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-q-commerce-js": () => import("./../../../src/pages/q-commerce.js" /* webpackChunkName: "component---src-pages-q-commerce-js" */),
  "component---src-pages-qr-menu-maker-js": () => import("./../../../src/pages/qr-menu-maker.js" /* webpackChunkName: "component---src-pages-qr-menu-maker-js" */),
  "component---src-pages-reporting-js": () => import("./../../../src/pages/reporting.js" /* webpackChunkName: "component---src-pages-reporting-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-virtual-concept-js": () => import("./../../../src/pages/virtual-concept.js" /* webpackChunkName: "component---src-pages-virtual-concept-js" */),
  "component---src-pages-why-orders-co-js": () => import("./../../../src/pages/why-orders-co.js" /* webpackChunkName: "component---src-pages-why-orders-co-js" */),
  "component---src-templates-blog-author-js": () => import("./../../../src/templates/blog-author.js" /* webpackChunkName: "component---src-templates-blog-author-js" */),
  "component---src-templates-blog-post-archive-js": () => import("./../../../src/templates/blog-post-archive.js" /* webpackChunkName: "component---src-templates-blog-post-archive-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

